import {
  Button,
  ButtonClicked,
} from "components/core";
import FormField from "components/forms/FormField";
import { DATA_STATUS } from "constants.js";
import { useFarms } from "context/AppDataProvider";
import {
  IForm,
  IFormField,
  getFormDataStatus,
  IFormValue,
  IFormValueDataSources,
  getPenDataFromFormData,
  IFormData,
  IPenFormValid,
} from "helpers/formUtilities";
import useQuery from "hooks/useQuery";
import { useEffect, useMemo, useState, useRef } from "react";
import { useNotifications } from "context/NotificationProvider";
import { DocumentDownloadIcon } from "assets/icons";
import { buildFormValues } from "helpers/formsUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

interface ReportFormProps {
  selectedFormId: string;
  forms: IForm[];
}

const ReportForm: React.FC<ReportFormProps> = ({
  selectedFormId,
  forms,
}) => {
  const [formValues, setFormValues] = useState<IFormData | undefined>(
    undefined
  );
  const [formValid, setFormValid] = useState<IPenFormValid[]>([]);
  const [isProcessingButtonVisible, setIsProcessingButtonVisible] =
    useState<Boolean>(false);

  let query = useQuery();
  let farmId = query.get("farmId");
  
  const { farms } = useFarms();
  const { addNotification } = useNotifications();

  const abortControllerRef = useRef(undefined) as any;

  const farm = useMemo(
    () =>
      farms.find(
        (f: { FarmCode: string }) =>
          f.FarmCode.toLowerCase() === farmId?.toLowerCase()
      ),
    [farms, farmId]
  );

  const selectedForm = useMemo<IForm | undefined>(
    () =>
      forms?.find(
        (f: { FormName: string }) => f.FormName === selectedFormId
      ),
    [forms, selectedFormId]
  );

  const dataStatus = useMemo<Number | undefined>(
    () => getFormDataStatus(formValid),
    [formValid]
  );

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  async function handleOnSubmit(fileFormat = "pdf") {
    addNotification({
      title: "Downloading report...",
    });

    // Prevent submission when dataStatus is NOT complete or draft
    if (dataStatus !== DATA_STATUS.COMPLETE) {
      addNotification({
        title: "Form is not complete",
        description: "Please fill out all required fields",
        theme: "error",
      });

      return;
    }

    setIsProcessingButtonVisible(true);

    const { signal } = abortControllerRef.current;

    const allowedFormFieldRefs = selectedForm?.FormFields?.map(
      (f: IFormField) => f.Ref?.toLowerCase()
    );

    const _reportParams = formValues?.PenValues?.[0].Values?.reduce(
      (acc: { [key: string]: any }, curr) => {
        if (allowedFormFieldRefs?.includes(curr.Ref?.toLowerCase())) {
          acc[curr.Ref] = curr.Value;
        }
        return acc;
      },
      {}
    );

    try {
      const response = await fetch("/api/runreport-post", {
        signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ReportName: selectedFormId,
          ReportParams: _reportParams,
          Format: fileFormat,
        }),
      });

      if (response.ok) {
        // if response content type is application/pdf
        if (response.headers.get("content-type")?.startsWith("application/")) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          const fileExtension = fileFormat === "pdf" ? ".pdf" : ".xls";
          link.href = url;
          link.download = `${selectedForm?.FormName}_${
            _reportParams !== undefined
              ? Object.values(_reportParams).join("_")
              : ""
          }${fileExtension}`;
          link.click();
          // window.open(url);
        }

        addNotification({
          title: "Report downloaded",
          theme: "success",
        });
      } else {
        addNotification({
          title: "Invalid request",
          theme: "warning",
          description:
            "The data provided is invalid, please check and try again. If the problem persists, please contact our support team.",
        });
      }
    } catch (error) {
      console.error(error);

      addNotification({
        title: "Error submitting report",
        theme: "error",
        description:
          "An error occurred while fetching the report. Please try again. If the problem persists, please contact our support team.",
      });
    } finally {
      if (signal.aborted) return;
      setIsProcessingButtonVisible(false);
    }
  }

  const handleSetFieldValue = (
    field: IFormField,
    penId: string,
    formValue: IFormValue
  ) => {
    if (!field?.Ref || !penId)
      return new Error("'ref' and 'pen' are required properties.");

    setFormValues((prevState) => {
      const dataSources: IFormValueDataSources = {
        this: prevState ?? {},
      };

      try {
        const newState = buildFormValues(
          field,
          penId,
          formValue,
          selectedForm,
          dataSources,
          undefined,
          undefined,
          0,
          undefined
        );

        return newState;
      } catch (error) {
        console.error(error);
      }

      return prevState as any;
    });
  };

  const handleSetFieldValid = (
    fieldId: string, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    field: IFormField,
    penNumber: string,
    valid: boolean,
    complete: boolean,
    required: boolean
  ) => {
    setFormValid((prevState) => {
      const newFormValid = prevState.filter(
        (fv) =>
          !(
            fv.Ref === fieldId &&
            (isNullEmptyOrWhitespace(field.QuestionGroup) ||
              fv.QuestionGroup === field.QuestionGroup) &&
            fv.Pen.toString() === penNumber.toString()
          )
      );
      newFormValid.push({
        Ref: fieldId,
        QuestionGroup: field.QuestionGroup,
        Pen: penNumber,
        Valid: valid,
        Complete: complete,
        Required: required,
      });

      return newFormValid;
    });
  };

  if (!selectedForm) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col flex-grow space-y-4">
        {selectedForm.FormFields.map((ff) => (
          <FormField
            key={ff.Ref}
            {...{
              id: ff.Ref,
              field: ff,
              penNumber: "1",
              farm,
              setFieldValue: handleSetFieldValue,
              setFieldValid: handleSetFieldValid,
              formValues: getPenDataFromFormData("1", formValues),
            }}
          />
        ))}
      </div>
      <div className="flex space-x-4 mt-4 tablet:justify-end">
        {isProcessingButtonVisible ? (
          <ButtonClicked />
        ) : (
          <>
            <Button
              isFullWidth={undefined}
              theme="primary"
              label={undefined}
              onClick={undefined}
              disabled={undefined}
              icon={
                <DocumentDownloadIcon className="h-5 w-5 mr-1 text-primary-lighter" />
              }
              // icon={undefined}
              iconPosition="left"
              optionsHeading={undefined}
              optionProps={undefined}
              showOptions={undefined}
              onShowOptions={undefined}
              onHideOptions={undefined}
              showStats={undefined}
              showSearch={undefined}
              optionsFilteredByMeta={undefined}
              type="submit"
              options={[
                {
                  id: "pdf",
                  text: "PDF",
                  onClick: () => handleOnSubmit("pdf"),
                },
                {
                  id: "excel",
                  text: "Excel",
                  onClick: () => handleOnSubmit("excel"),
                },
              ]}
            >
              Download
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default ReportForm;
